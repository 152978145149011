<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">机构统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-input
                v-model="searchData.compName"
                type="text"
                size="small"
                placeholder="请输入单位名称"
                clearable
              />
            </div>
            <div title="开班时间" class="searchboxItem ci-full">
              <span class="itemLabel">开班时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.startTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div title="入驻时间" class="searchboxItem ci-full">
              <span class="itemLabel">入驻时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.checkInTime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div style="margin-bottom: 15px">
              <span style="margin-right:15px;">合计机构总数：{{ dataStatistics.countComp || 0 }}个</span>
              <span style="margin-right:15px;">开班总数：{{ dataStatistics.countProject || 0 }}个</span>
              <span style="margin-right:15px;">培训学员总数：{{ dataStatistics.countUser || 0 }}人</span>
              <span style="margin-right:15px;">结业学员总数：{{ dataStatistics.countGraduatUser || 0 }}人</span>
            </div>
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="80px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="280"
                fixed
              />
              <el-table-column
                label="开班总数"
                align="left"
                show-overflow-tooltip
                prop="countProject"
                width="100"
              />
              <el-table-column
                label="培训学员总数"
                align="left"
                min-width="220"
                show-overflow-tooltip
                prop="countUser"
                width="120"
              ></el-table-column>
              <el-table-column
                label="结业人员总数"
                align="left"
                prop="countGraduatUser"
                width="120"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="培训课程总量"
                align="left"
                prop="countCourse"
                width="120"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="累计培训总学时"
                align="left"
                prop="courseLessonCount"
                width="120"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="入驻时间"
                align="left"
                prop="createTime"
                min-width="140"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                min-width="100px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="shiftOpeningRecord(scope.row)"
                    >开班记录</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "questionbankList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchData: {
        // 单位名称
        compName: "",
        // 开始时间
        startTime: [],
        // 入住时间
        checkInTime: [],
      },
      // 获取列表统计数据
      dataStatistics: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    // 获取列表数据
    async getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.compName) {
        params.compName = this.searchData.compName;
      }
      if (this.searchData.startTime) {
        params.startDate = this.searchData.startTime[0];
        params.endDate = this.searchData.startTime[1];
      }
      if (this.searchData.checkInTime) {
        params.joinStartDate = this.searchData.checkInTime[0];
        params.joinEndDate = this.searchData.checkInTime[1];
      }
      // 获取列表统计数据
      await this.$post("/run/project/mechanismStatisticsTotal", params).then(
        (res) => {
          // 机构总数
          this.dataStatistics.countComp = res.data.countComp || "";
          // 结业学员总数
          this.dataStatistics.countGraduatUser =
            res.data.countGraduatUser || "";
          // 开班总数
          this.dataStatistics.countProject = res.data.countProject || "";
          // 学员总数
          this.dataStatistics.countUser = res.data.countUser || "";
        }
      );
      this.doFetch({
        url: "/run/project/mechanismStatistics",
        params,
        pageNum,
      });
    },
    // 开班记录
    shiftOpeningRecord(row) {
      this.$router.push({
        path: "/web/operate/compAgencyClassStart",
        query: {
          compId: row.compId,
        },
      });
    },
    // 列表高度计算
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
</style>
